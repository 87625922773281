import React from "react";
import Carousels from "./carousel";
import {Link} from 'react-router-dom'
import { announcements } from "../../../data/erusmas";
import { GrAnnounce } from "react-icons/gr";
import { BiNews } from "react-icons/bi";

const Erusmas = () => {
  const backet =
    "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
  const campus = backet + "/admission/ardayshidan.PNG";

  const img1 = backet + "/news/news01/1.jpg";
  const img2 = backet + "/news/news01/2.jpg";
  const img3 = backet + "/news/news01/3.jpg";

  const map = backet + "/erasmus/temp.png";
  

  const myDate = new Date("2021, 10, 28").toLocaleDateString("en-US", {
    weekday: 'long',
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const announcements1 = [
    {
      id: 1,
      title: "2024 PROJECT ERASMUS+ KA131 STUDENT MOBILITY FOR TRAINEESHIPS",
      date: myDate.toString(),
      icon: <GrAnnounce />,
      link: "student"
    },
    {
      id: 2,
      title: "2023-2024 KA171 STAFF MOBILITY APPLICATIONS",
      date: myDate.toString(),
      icon: <GrAnnounce />,
      link: "staff"
    },
  ];

  const events = [
    {
      id: 1,
      title: "2023-2024 ERASMUS EXPERIENCES",
      date: myDate.toString(),
      icon: <GrAnnounce />,
      link: "#"
    },
    {
      id: 2,
      title: "2023-2024 INTERVIEW WITH ERASMUS+ KA107 STUDENT",
      date: myDate.toString(),
      icon: <GrAnnounce />,
      link: "#"
    },
  ];


  const myDate1 = new Date("2021, 10, 03").toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const news = [
    {
      id: 1,
      date: myDate1.toString(),
      title: "International student culture night",
      img: img1,
    },
    {
      id: 2,
      date: myDate1.toString(),
      title: "International student culture night",
      img: img1,
    },
    {
      id: 3,
      date: myDate1.toString(),
      title: "International student culture night",
      img: img1,
    },
  ];

  return (
    <div className="">
      <Carousels />
      <div className="flex flex-row items-center justify-center xl:py-20 md:py-16 md:mx-40 xl:mx-64 sm:mx-3 xs:mx-3 py-5 ">
        <p className="text-xl leading-8 -tracking-wide">
          {" "}
          <strong>Erasmus+ Programme </strong> is the programme that covers
          grant support in the field of education, youth and sports of the
          European Union. Within the scope of the programme, individuals are
          offered the opportunity to take part in youth activities based on
          education, training, internship, professional development and
          non-formal learning abroad. In addition, collaborations between
          institutions are supported through partnership activities. European
          Solidarity Programme (European Solidarity Corps, ESC); is a new
          European Union initiative that aims to meet social needs, creating
          opportunities for young people to volunteer, work or network on
          projects that benefit society in their own country or abroad, while
          promoting their personal, educational, social, civil and professional
          development.{" "}
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-5  md:mx-40 xl:mx-64 my-10 mb-10 mx-3">
        {/* column one */}
        <div>
          <p className="border-l-2 px-2 border-[#0843aafe] my-5 text-2xl font-semibold">
            Announcements
          </p>
          {announcements.map((a) => (
            <Link to={a.link} target="_blank" rel="noopener noreferrer" key={a.id} className="flex flex-row mx-8 my-3">
              <p className="m-3 text-3xl">{a.icon}</p>
              <div className="flex flex-col">
                <p className="text-sm text-gray-400">{a.date}</p>
                <p className="text-xl">{a.title}</p>
              </div>
            </Link>
          ))}
        </div>

        {/* column two */}
        {/* <div>
          <p className="border-l-2 px-2 border-[#0843aafe] my-5 text-2xl font-semibold">
            Events
          </p>
          {events.map((a) => (
            <a href={a.link} key={a.id} className="flex flex-row mx-8">
              <p className="m-3 text-3xl">{a.icon}</p>
              <div className="flex flex-col">
                <p className="text-sm text-gray-400">{a.date}</p>
                <p className="text-xl">{a.title}</p>
              </div>
            </a>
          ))}
        </div> */}
      </div>


      {/* news */}
      <div className="bg-[#dddddd]">
            <div className="bg-[#dddddd] py-10  md:mx-40 xl:mx-64 mx-3">
        <div>
          <p className="border-l-2 px-2 border-[#0843aafe] my-10 text-3xl font-semibold">
            What's new
          </p>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-10 justify-center items-center px-10 my-5">
          {/* card one */}
          {news.map((n) => (
            <div key={n.id} className="flex flex-col  relative bg-white hover:cursor-pointer ">
              <img src={n.img} alt="not found" className="xl:w-[420px] xl:h-[300px] md:w-[420px] md:h-[300px]" />
              <div className="mx-10 space-y-3 relative py-10">
                <p className=" bg-[#d4d4d4]  p-1 w-fit top-[-20px] absolute text-center">
                  {n.date}{" "}
                </p>
                <p className="my-5">{n.title} </p>
                <div className="flex flex-row space-x-2 items-center py-3">
                  <BiNews />
                  <p>news </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center items-center my-5 mt-20">
          <p className=" text-2xl font-semibold border-2 w-fit p-5 hover:cursor-pointer rounded-lg border-[#0843aafe] text-[#0843aafe]">
            Get all the letest news
          </p>
        </div>
      </div> 
      </div>
     
      {/* contacts */}
      <div className="py-10  md:mx-40 xl:mx-64 mx-3">
        <div>
          <p className="border-l-2 px-2 border-[#0843aafe] my-5 text-3xl font-semibold text-[#0843aafe]">
            Contacts
          </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-5 justify-center  px-10">
          {/* card one */}
          <div className="my-10 text-xl">
            <p>Tell: -25261xxxxxxx </p>
            <p>Email: erasmus@bu.edu.so</p>
            <p>
              Address: Address: Prof. Addow Campus, Wadada Jaamacada Benadir,
              Hodan, Mogadishu, Somalia
            </p>
          </div>
          <div>
            <img src={map} alt="map" className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Erusmas;
