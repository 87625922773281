import React, { useState } from 'react'



const Scholarshipopportunities = () => {

  return (
    <div className='flex flex-col md:mx-10 xl:mx-10 mx-3 text-black  place-items-center '>
      <div className='flex flex-col  md:w-[80%] xl:w-[80%]'>
      <p className='text-2xl  sm:text-xl xs:text-xl font-semibold font-montserrat'>Introduction to "Rajo kaab" Scholarship</p>
      <div className='xl:text-xl md:text-xl font-base text-base leading-8 whitespace-pre-line my-3 space-y-3 tracking-wide'>
      <p>
      Benadir University, in collaboration with the Minister of Higher Education <strong>(WWHTS)</strong>, is offering an exciting scholarship opportunity (called Rajo Kaab) for students pursuing a diploma in midwifery, nursing, and education. This scholarship aims to empower women and girls by providing them with the chance to study in fields that are crucial for the development of society. The midwifery and nursing programs are specifically open to women, offering them the chance to contribute to healthcare services while gaining professional expertise in these vital areas. By supporting women in healthcare, the scholarship not only enhances their career prospects but also ensures a better, more accessible healthcare system for communities.
    </p>
    <p>
    Additionally, the diploma in education program is designed exclusively for women, focusing on teaching arts and teacher training. Students in this program will develop essential skills in pedagogy, classroom management, and instructional design, preparing them to become effective educators in diverse learning environments. Through this initiative, the scholarship aims to cultivate a new generation of female teachers who can inspire and positively impact the education sector. Whether in healthcare or education, these scholarship programs are committed to fostering the growth of skilled women professionals who will contribute to the betterment of society.
    </p>
    <p className='text-xl  sm:text-base xs:text-base font-semibold font-montserrat'>Programms:</p>
    <ol className='list-decimal font-semibold text-base xs:text-sm px-3'>
      <li>Diploma in midwifery</li>
      <li>Diploma in nursing</li>
      <li>Diploma in education</li>
    </ol>

    <p className='text-xl  sm:text-base xs:text-base font-semibold font-montserrat'>How to apply</p>
    <p className='text-blue-700 hover:underline text-base xs:text-sm'>
      <a href="https://drive.google.com/file/d/1RCIPx68xNZrk4NSyP-jA3cyH-2fO622h/view?usp=sharing" target='_blank'>
        Watch a tutorial about how to fill the application form.
      </a>
    </p>
    <p className='text-blue-700 hover:underline text-base xs:text-sm'>
      <a href="https://scholarship.moe.gov.so/auth/signup" target='_blank'>
        scholarship application link
      </a>
    </p>

    </div>

      </div>
</div>
)
}

export default Scholarshipopportunities